<template>
  <div class="menu-form tickets">
    <Header :withMerchant="false" />
    <section class="main">
      <Event :ticketsCount="ticketsCount" />
      <SelectTicketType v-if="ticketsCount" />
      <ReceiptSend v-if="ticketsCount" :type="'tickets'" />
      <PayMethods v-if="ticketsCount" />
    </section>
    <Footer v-if="ticketsCount" />
  </div>
</template>

<script>
import Event from "@/components/tickets/Event";
import SelectTicketType from "@/components/tickets/SelectTicketType";
import ReceiptSend from "@/components/checkout/ReceiptSend";
import PayMethods from "@/components/checkout/PayMethods";
import getMenuInfo from "@/mixins/getMenuInfo";
import setRoute from "@/mixins/setRoute";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import { mapGetters } from "vuex";

export default {
  mixins: [getMenuInfo, setRoute],
  components: {
    Footer,
    Event,
    SelectTicketType,
    ReceiptSend,
    PayMethods,
    Header,
  },
  computed: {
    ...mapGetters({
      tickets: "tickets",
    }),
    ticketsCount() {
      return this.tickets?.find((item) => item.name === "items")?.value.length;
    },
  },
  created() {
    localStorage.setItem(
      "templateId",
      JSON.stringify(this.$route.query.template)
    );
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/pages/tickets.scss";
</style>
