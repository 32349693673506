<template>
  <section v-if="tickets" class="ticket-type">
    <h2 class="ticket-type__title order__title">Выберите тип билета</h2>

    <div class="editable-products__products">
      <EditableOneProduct
        v-for="ticket in ticketsList"
        :type="'tickets'"
        :key="ticket.id"
        :product="ticket"
        class="editable-products__product"
      />
    </div>

    <h3 class="error-message" :class="{ show: totalError }">Вы ничего не выбрали</h3>

    <div class="editable-products__total">
      <h2 class="order__title editable-products__title">Итого</h2>
      <div class="order__amount editable-products__amount">
        <money :value="total" />
      </div>
    </div>
  </section>
</template>

<script>
import EditableOneProduct from "@/components/checkout/EditableOneProduct";
import { mapGetters } from "vuex";

export default {
  components: {
    EditableOneProduct,
  },
  computed: {
    ...mapGetters({
      tickets: "tickets",
      total: "total",
      totalError: "totalError",
    }),
    ticketsList() {
      return this.tickets
        ? this.tickets.find((item) => item.name === "items")?.value
        : null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/pages/tickets.scss";
</style>
