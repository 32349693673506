<template>
  <section class="receipt-send">
    <h2
      class="order__title receipt-send__title"
      v-html="
        `Куда вам отправить чек ${type === 'tickets' ? '<br> и билеты' : ''}?`
      "
    ></h2>

    <div class="receipt-send__fields">
      <div v-if="sendType === 'phone'">
        <masked-input
          ref="phone"
          v-model="phoneNumber"
          type="text"
          class="order__input receipt-send__input"
          :class="{ invalid: !this.isValid }"
          :mask="phoneMask"
          placeholder="Введите телефон"
          placeholderChar="_"
        >
        </masked-input>
      </div>
      <div v-if="sendType === 'email'">
        <label>
          <input
            type="text"
            @input="changeEmail"
            class="order__input receipt-send__input"
            :class="{ invalid: !this.isValid }"
            placeholder="Введите e-mail"
          />
        </label>
        <div
          v-if="validation['email'] !== ''"
          class="field__validation-message receipt-send__validation-message"
        >
          {{ validation["email"] }}
        </div>
      </div>

      <div class="receipt-send__changing">
        <label>
          <select @change="onSendTypeChange" :value="sendType">
            <option value="phone">Телефон</option>
            <option value="email">Почта</option>
          </select>
        </label>
      </div>
    </div>
  </section>
</template>

<script>
import MaskedInput from "vue-text-mask";

import { mapMutations, mapGetters } from "vuex";
import { SET_VALUE } from "@/store/mutations.type";
import { masks } from "@/constants";

export default {
  components: {
    MaskedInput,
  },
  props: ["type"],
  data: () => ({
    phoneNumber: "",
  }),
  computed: {
    ...mapGetters(["phone", "email", "sendType", "validation", "isValid"]),
    feedback() {
      return this.feedbackBlock;
    },
    phoneMask: () => masks.phone.ru,
  },
  watch: {
    phoneNumber(value) {
      this.setValue({ key: this.sendType, value });
      this.setValue({ key: "isValid", value: true });
    },
  },
  methods: {
    onSendTypeChange(e) {
      this.setValue({ key: "sendType", value: e.target.value });
      this.setValue({ key: "isValid", value: true });
    },
    ...mapMutations({
      setValue: SET_VALUE,
      setEmail: "pay/setEmail",
    }),
    changeEmail(e) {
      this.setEmail({ email: e.target.value });
      this.setValue({ key: this.sendType, value: e.target.value });
      this.setValue({ key: "isValid", value: true });
    },
  },
  beforeMount() {
    this.setValue({ key: "phone", value: "" });
    this.setValue({ key: "email", value: "" });
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/components/receipt-send.scss";
</style>
