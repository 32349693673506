<template>
  <div v-if="qty || type === 'tickets'">
    <div class="editable-products__product-name">{{ product.name }}</div>
    <MenuButton
      :price="product.price"
      :quantity="qty"
      :onAdd="add"
      :onRemove="remove"
    />
  </div>
</template>

<script>
import MenuButton from "@/components/MenuButton";
import editableMenuItem from "@/mixins/editableMenuItem";
import cartBlockChange from "@/mixins/cartBlockChange";
import { mapGetters } from "vuex";

export default {
  mixins: [editableMenuItem, cartBlockChange],
  props: ["product", "type"],
  components: {
    MenuButton,
  },
  computed: {
    ...mapGetters(["pagePath", "total"]),
    cartProduct() {
      return this.cardBlock.body
        .find((item) => item.name === "items")
        ?.value.find((product) => product.name === this.product.name);
    },
  },
  watch: {
    "product.count"(val) {
      this.cartProduct.quantity = val;
    },
    total(val) {
      this.updateCardBlock(val);
    },
  },
  methods: {
    localeProducts() {
      const products = [];
      for (const key in localStorage) {
        if (key.split("_")[0] === this.pagePath) {
          products.push(JSON.parse(localStorage[key]));
        }
      }
      return products.map((product) => ({
        id: product.id,
        name: product.name,
        price: product.price,
        quantity: product.count,
      }));
    },
    updateCardBlock(total) {
      this.cardBlock.body.find((item) => item.name === "items").value =
        this.localeProducts();
      this.cardBlock.body.find((item) => item.name === "total").value = total;
    },
  },
  mounted() {
    if (this.cardBlock) {
      this.updateCardBlock(this.total);
    }
  },
};
</script>
