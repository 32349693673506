<template>
  <section class="pay-methods">
    <h2 class="order__title pay-methods__title">Способы оплаты</h2>
    <div class="pay-methods__buttons">
      <!--        :class="'pay-methods__button_' + pm"-->
      <button
        v-for="(pm, index) in payMethods"
        :key="pm"
        class="pay-methods__button"
        :class="{
          active: cardPay && pm === 'card',
        }"
        @click="payment(pm)"
      >
        <!-- <img
          :src="
            require(`@/assets/img/${
              pm === 'card' && cardPay ? pm + '_white' : pm
            }.svg`)
          "
          alt
        /> -->
        <img
          :src="
            require(`@/assets/img/${index % 2 === 0 ? pm + '_white' : pm}.svg`)
          "
          alt
        />
        <span v-if="pm === 'card'">Карта</span>
      </button>
    </div>

    <div class="card-form" v-if="cardPay">
      <div
        id="payment-form"
        class="fns-payment-form"
        :class="{ 'fns-payment-form_hidden': !cardPay }"
      ></div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { REQUEST_ORDER } from "@/store/actions.type";
import { Block } from "@/constants";
import { getBlock } from "@/store/modules/menu";
import { validateEmail, validatePhone } from "@/utils";
import {
  CHANGE_TOTAL_ERROR,
  FEEDBACK_EMPTY_CHANGE,
  SET_VALUE,
} from "@/store/mutations.type";

export default {
  data: () => ({
    cardPay: false,
  }),
  computed: {
    ...mapGetters([
      "paymentMethods",
      "paymentBlock",
      "pageTemplate",
      "feedbackValue",
      "email",
      "phone",
      "sendType",
      "isValid",
      "route",
      "total",
      "totalAmount",
      "totalError",
    ]),
    payMethods() {
      return this.paymentMethods?.body.find((item) => item.name === "methods")
        .value;
    },
  },
  methods: {
    ...mapActions({
      requestOrder: `pay/${REQUEST_ORDER}`,
    }),
    ...mapMutations({
      setValue: SET_VALUE,
      feedBackEmptyChange: FEEDBACK_EMPTY_CHANGE,
      setTotalError: CHANGE_TOTAL_ERROR,
    }),
    validate() {
      if (this.sendType === "phone") {
        this.setValue({
          key: "isValid",
          value: validatePhone("ru", this.phone),
        });
      }
      if (this.sendType === "email") {
        this.setValue({
          key: "isValid",
          value: validateEmail(this.email),
        });
      }
      return this.isValid;
    },
    feedbackValidate() {
      const feedbackForm = document.querySelector(".feedbackForm");
      if (feedbackForm) {
        const phone = this.feedbackValue?.find(
          (item) => item.name === "phone"
        ).value;
        this.feedBackEmptyChange(validatePhone("ru", phone));
        return validatePhone("ru", phone);
      }
      return true;
    },

    async payment(pm) {
      if ([this.validate(), this.feedbackValidate()].some((item) => !item)) {
        window.scrollTo(0, 0);
        return;
      }

      if (!+this.total && !+this.totalAmount) {
        this.setTotalError(true);
        window.scrollTo(0, 0);
        return;
      }
      if (pm === "card") this.cardPay = true;

      const feedbackForm = document.querySelector(".feedbackForm");
      if (feedbackForm) {
        getBlock(
          this.pageTemplate,
          this.route.current,
          Block.FeedbackBlock
        ).body = this.feedbackValue;
      }
      try {
        const templateId = JSON.parse(localStorage.getItem("templateId"));
        await this.requestOrder({
          cardBlock: {
            ...this.pageTemplate,
            template_id: templateId,
          },
          payMethod: pm,
        });

        if (pm !== "card") await this.$router.push("/checkout-success");
      } catch (e) {
        if (e === "item_not_available" || e === "all_not_available") {
          await this.$router.push(
            `/checkout-not-available?template=${this.$route.query.template}&error=${e}`
          );
        } else {
          await this.$router.push("/checkout-error");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/components/pay-methods.scss";
</style>
